<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar id="landing-page">
                <!-- color="#035233" -->
                <!-- <v-toolbar-title >Login form</v-toolbar-title> -->
              </v-toolbar>
              <v-card-text>
                <form ref="form" @submit.prevent="loginuser()">
                  <v-text-field
                    v-model="form.username"
                    filled
                    name="username"
                    label="Username"
                    type="text"
                    required
                  ></v-text-field>

                  <!-- <v-text-field
                              v-model="form.password"
                              filled
                              name="password"
                              label="Password"
                              type="password"
  
                              required
                           ></v-text-field> -->
                  <v-text-field
                    v-model="form.password"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    name="password"
                    label="password"
                    class="input-group--focused"
                    @click:append="show2 = !show2"
                  >
                  </v-text-field>

                  <v-btn type="submit" class="mt-4 white--text" color="#035233"
                    >Sign in
                  </v-btn>
                  <v-overlay :value="overlay">
                    <v-progress-circular indeterminate size="64">
                      <v-avatar color="orange" size="62">
                        <img :src="require('../assets/bds.png')" />
                      </v-avatar>
                    </v-progress-circular>
                  </v-overlay>
                </form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import utils from "../utils";
import constant from "../constant";
export default {
  /* eslint-disable */

  name: "Login",
  data() {
    return {
      loader: null,
      loading: false,
      overlay: false,
      show2: false,
      form: {
        username: "",
        password: "",
      },
      constant: constant,
      backgroundLoading: "#22c16b",
    };
  },
  methods: {
    loginuser() {
      // constant.getCookie().then(()=>
      // {

      // });

      const self = this;
      const f = new FormData();

      f.append("username", this.form.username);
      f.append("password", this.form.password);
      this.axios
        .post(utils._api(this.constant.loginuser), f, {
          headers: this.constant.headers_tokenless,
        })
        .then((response) => {
          //   this.$vs.loading({background:this.backgroundLoading,color:'rgb(255, 255, 255)'})

          const status = response.data.status;
          if (status == 1) {
            const data = response.data.data;
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("id", data.id);
            localStorage.setItem("fullname", data.fullname);
            localStorage.setItem("isAdmin", data.isAdmin);
            localStorage.setItem("user_info", JSON.stringify(data));

            //  this.setLogState(true);
            //  this.setUserRole(response.data.isAdmin);
            //  this.setUserInfo(data);
            // console.log("credentials" + response.data.token);
            this.overlay = !this.overlay;
            setTimeout(() => {
              // this.loader = true;
              this.$router.push({ name: "Admin" });
              this.$toaster.success("Successful Login!", { timeout: 5000 });
              this.overlay = !this.overlay;
            }, 5000);
          } else {
            if (status == 0) {
              this.$toaster.warning("No Match Record!", { timeout: 8000 });
            }
            if (status == -1) {
              this.$toaster.error("Wrong Credentials!", { timeout: 8000 });
            }
            if (status == -2) {
              this.$toaster.error("Sorry! Your account is no longer Active. ", {
                timeout: 8000,
              });
            }
          }
        });
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 5000);
    },
  },
  mounted() {
    //  this.loginuser();
  },
};
</script>
<style>
#landing-page {
  background-image: url("../assets/bds2.png");
  /* background-color: #8f2c2c; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 100px;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>