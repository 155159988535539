// eslint-disable 
import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
// import AboutView from '../views/AboutView.vue'
// import Login from '../views/Login.vue'
// import sales from '../components/tabBar/sales.vue'
// import Headers from '../views/Headers.vue'
Vue.use(VueRouter)

const authenticated = (to, from, next) => {
  let auth =  localStorage.getItem("token");
  if (auth) {
    next()
    return
  }
  else {
    next()
    return
  }
  
}
function guest(to,from,next){
  let auth = localStorage.getItem("token");
  if(auth){  
    next({name : "Admin"});
  }else next();
    
}
// const guard = function(to, from, next) {
//   // check for valid auth token
//   axios.get('/api/checkAuthToken').then(response => {
//       // Token is valid, so continue
//       next();
//   }).catch(error => {
//       // There was an error so redirect
//       window.location.href = "/login";
//   })
// };
const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: guest
    
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: () => import('@/views/AdminPage'),
    beforeEnter: (to, from, next) => {
      authenticated(to, from, next);
    }
 
   
  },
  // {
    
  //   path: '/User',
  //   name: 'User',
  //   component: () => import('@/views/UserPage'),
  //   beforeEnter: (to, from, next) => {
  //     authenticated(to, from, next);
  //   }
    
    
  // },
  // //via components here
  {
    
    path: '/test',
    name: 'inventory',
    component: () => import('@/components/Admin/inventory'),
    
    
  },
  {
    
    path: '/report',
    name: 'sales',
    component: () => import('@/components/reports/sales.vue'),
    
    
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
