import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
// import * as Vue from 'vue' // in Vue 3
// import axios from 'axios'
// import VueAxios from 'vue-axios'
import Toaster from 'v-toaster'
import 'v-toaster/dist/v-toaster.css'
// optional set default imeout, the default is 10000 (10 seconds).
Vue.use(Toaster, {timeout: 5000})


import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios) 
import QrcodeVue from 'qrcode.vue'
// import VueQRCodeComponent from 'vue-qr-generator'
// Vue.component('qr-code', VueQRCodeComponent)

//htmlcanvas

Vue.mixin({
    methods: {
      onlyNumber ($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    }
  }
})

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  QrcodeVue,
  render: h => h(App)
}).$mount('#app')
