
 
export default {

    // main_site: 'http://localhost:8080/',
    // web_local: 'http://localhost:8080/',
    // // apiv1: 'http://localhost:8081/api',
    // apiv1: 'https://www.semi-majestic.com:444/api',

    // main_site: 'http://18.140.65.233/',
    // web_local: 'http://18.140.65.233/',
    // apiv1: 'http://18.140.65.233:81/api',
    // apiv1:'https://www.barrogadental.com:444/api',
    // main_site: 'http://13.215.194.217/',
    // web_local: 'http://13.215.194.217/',
    // apiv1: 'http://13.215.194.217:81/api',
    // csrf: apiv1.defaults.withCredentials =true,
    apiv1:'https://www.barrogadental.com:444/api',
    main_site: 'http://13.215.194.217/',
    web_local: 'http://13.215.194.217/',
    api_full_url: window.location.origin,
    bearer_token: `?token=${localStorage.getItem('token')}`,
    headers: {
        'Content-Type': 'application/json',
        'Accept' : 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    headers_tokenless: {
        'Content-Type': 'application/json',
        'Accept' : 'application/json',
        'Access-Control-Allow-Origin': '*'
    },
    //importdata 
    import : '/uploadFile',
    
    loginuser: '/loginuser',
    registeruser: '/register',
    logs: '/v1/User/logs',
    //User
    userslist : '/v1/User/accounts',
    actordeactivate : '/v1/User/deactivate',

    //inventory
    addinventory: '/v1/Inventory/add',
    checkstock: '/v1/Inventory/stock',
    itemlist: '/v1/Inventory/get',
    addtocheckout: '/v1/Inventory/addtocheckout',
    edit: '/v1/Inventory/edit',
    searchitem: '/v1/Inventory/searchedit',
    searchitemrecheckout: '/v1/Inventory/listsearchedit',
    receipt : '/v1/Inventory/receipt',
    itemactordeactive : '/v1/Inventory/deactivate',
    deleteiteminventory: '/v1/Inventory/delete',
    confirmrefund: '/v1/Inventory/refund',
    //Checkout
    addorder:'/v1/Checkout/addorder',
    editcheckorder: '/v1/Checkout/edit',
    getorderlist: '/v1/Checkout/getorderlist',
    orderlist: '/v1/Checkout/orderlist',
    findtransaction: '/v1/Checkout/findtransaction',
    find_orderlist : '/v1/Checkout/find_orderlist',
    refund_orderlist: '/v1/Checkout/refund_orderlist',
 
    //Expense
    addexpe:'/v1/Expense/addexpe',
    showexpense: '/v1/Expense/showexpense',
    daily_expense_by_date: '/v1/Expense/daily_expense_by_date',
    monthly_expense_by_date: '/v1/Expense/monthly_expense_by_date',
    


    //Sales
    salesreport:'/v1/Sales/salesreport',
    totallazada:'/v1/Sales/tlazada',
    totalshopee:'/v1/Sales/tshopee',
    totalwalkin:'/v1/Sales/twalkin',
    //totalallsalespayment
    all_sales_by_purchase: '/v1/Sales/all_sales_by_purchase',
    update: '/v1/Sales/update',
    all_sales: '/v1/Sales/all_sales_report',
    monthly_sales : '/v1/Sales/monthly_sales',
    product_monthly : '/v1/Sales/product_monthly',
    daily_sales_by_date : '/v1/Sales/daily_sales_by_date',

    //SplitBill
    createSplitBill:'/v1/SplitBill/split-bill',
    makeSettlement:'/v1/SplitBill/split-bill/settlement',
    // makeSettlement:'/v1/SplitBill/split-bill/settlement',
    getPayments: '/v1/SplitBill/split-bill/get-payments',

       // Dashboard
       weeklyTransaction: '/v1/Dashboard/weekly-transaction',
       dailyCountTransaction: '/v1/Dashboard/daily-count-transaction',
       salesBreakdownTransaction: '/v1/Dashboard/sales-breakdown-transaction',
       addReminder: '/v1/Dashboard/save-reminder',
       showReminder: '/v1/Dashboard/get-reminder',  
       lowStock: '/v1/Dashboard/low-stock',
       weeklyIncome: '/v1/Dashboard/weekly-income'

}
// import utils from "./utils"
// import axios from "axios"





